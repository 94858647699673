.hero__content h2 {
  font-weight: 600;
  line-height: 55px;
}

.hero__content {
  padding-top: 80px;
}
.search input {
  border: none;
  outline: none;
  padding: 7px 10%;
}

.search {
  width: 60%;
  border: 1px solid #17bf9d44;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 50px;
  margin-top: 20px;
}
.slider-img{
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}
.hero-holder{
  height: 60vh;
  position: absolute;
  bottom: 0;
  z-index: 11;
  right: 60%;
  
}
.overlay{
  height: 80vh;
  width: 100%;
  background: #004d9166;
  z-index: 1;
  position: absolute;
}
.carousel-caption h3, p{
  position: relative;
}

.carousel-caption{
  left: 40%!important;
  text-align: left!important;
  top:15%;
  z-index: 111;
}
.hero-header{
  font-size: 3rem;
  font-weight: bold;
  color: white;
  z-index: 999;
  position: relative;
}
.hero-sub{
  font-weight: normal;
  line-height: 30px;
  font-size: 1rem;
  color: white;
  z-index: 999;
  position: relative;
}
.btn-hero{
  display: inline-block;
  border-radius: 4px;
  background-color: #f37224;
  border: none;
  color: #FFFFFF!important;
  text-align: center;
  font-size: 17px;
  padding: 16px;
  width: 150px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
  font-weight: bold;
  border-radius: 50px;
  z-index: 999;
  position: relative;
}
.btn-hero span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
 }
 
 .btn-hero span:after {
  content: '»';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -15px;
  transition: 0.5s;
 }
 
 .btn-hero:hover span {
  padding-right: 15px;
 }
 
 .btn-hero:hover span:after {
  opacity: 1;
  right: 0;
 }

 .hero-banner{
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  color: white;
 }
 a{
    z-index: 999!important;
    text-decoration: none!important;
    color: #054f8e;
 }
@media only screen and (max-width: 700px) {
  .hero-header{
    font-size: 2rem;
  }
  .hero-sub{
    font-size: 1rem;
  }
}
@media only screen and (max-width: 650px) {
  .col-text{
    flex: unset!important;
    padding-bottom: 100px!important;
  }
  .hero-holder{
    right: 0;
    height: 45vh;
  }
  .hero-banner .col img{
    display: none;
  }
}
@media only screen and (max-width: 500px) {
  .hero-holder{
    height: 35vh;
  }
}