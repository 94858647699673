@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Andada+Pro:wght@400;500;600;700;800&display=swap");

/* =========== base style ========= */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
li{
  list-style-type: none;
}
footer {
  padding-top: 70px;
  padding-bottom: 70px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 2rem;
  line-height: 50px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

p {
  font-size: 1rem;
  line-height: 35px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}
i {
  color: #17bf9e;
}
fieldset{
  border-color: rgba(255, 255, 255, 0)!important;
  border-radius: 20px;
}
.relative{
  position: relative;
  overflow: hidden;
}
.global-secondary{
  background-color: #56c8f373;
  color: black !important;
}
.counter-icon{
  width: 4rem;
  height: 4rem;
}
.footer h3,.footer h6{
  color:white!important;
}
 .footer a, .footer .copyright{
  color: #e1daea !important;
}
.footer a:hover{
  color: white!important;
}
.fw-5{
  font-weight: 600;
}
.slick-slide{
  background: #f0f0f0;
  padding: 30px;
  border-radius: 50px;
  border:20px solid #054f8e;
  color: black;
  /* margin: 20px; */
  /* transform: translate(-100px, 0px); */
}
#demo-simple-select-label{
  padding: 10px!important;
}
.list-item-custom{
  list-style-type: disc;
  line-height: 30px;
}
.imp-li{
  display: inline-block;
}
.imp-links{
  text-align: center;
}
@media (max-width: 768px)
{
  .logo img {
      height: 40px;
  }
}