.footer {
  background: #054f8e;
}

.follows span a {
  text-decoration: none;
}

.follows span {
  margin-right: 10px;
}

.link__list .link__item {
  background: transparent;
}
/* .vr-right::before{
  content: '';
  padding: 0;
    width: 3px;
    background: #f37224;
    margin: 0;
} */
.vr-custom{
  height: -webkit-fill-available;
  width: 1px;
  background: #e1daea;
  z-index: 999999;
  position: absolute;
  right: 0;
}
@media only screen and (max-width: 991px) {
  .vr-custom-2
  {
    display: none;
  }
  .logo.pb-3 img{
    width: 300px;
    height: auto;
    text-align: center;
  }
  .mb-4.relative, .col-md-6.col-lg-3{
    text-align: center;
  }
}
@media only screen and (max-width: 767px) {
  .vr-custom
  {
    display: none;
  }
}
.link__list a {
  text-decoration: none;
  color: #0a2b1e;
  font-weight: 500;
  font-size: 14px;
}
.c-white{
  color:white;
}
/* a{
  color: #191919!important;
} */