.header {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 99999999;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.nav__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  column-gap: 1.3rem;
  align-items: center;
}

.nav__item a {
  font-weight: 500;
  font-size: 0.9rem;
  color: #0a2b1e;
  text-decoration: none;
  transition: 0.3s;
}

.nav__item a:hover {
  color: #062743;
}

.nav__right p {
  font-weight: 500;
  color: #0a2b1e;
  font-size: 1.1rem;
}

.nav__right p i {
  font-size: 1.3rem;
}

.mobile__menu span i {
  font-size: 1.4rem;
  color: #0a2b1e;
  cursor: pointer;
}

.mobile__menu {
  display: none;
}
.global-primary{
  background-color: #054f8e;
  color: white !important;
}
.navbar a{
  color:#054f8e;
  text-decoration: none;
  font-weight: 500;
}
.social-links svg{
  color:#054f8e;
}
.dropdown{
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  position: absolute!important;
  background: white;
  padding-top: 25px;
  display: none;
  /* transform: translate(-140px, 0px); */
  padding: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.nav__item:hover .dropdown{
  display: block;
}
.drop_menu{
  cursor: pointer;
  transition: all 0.5s;
  padding: 5px 0px;
}
.drop_menu .drop_menu_a{
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
  font-size: 0.9rem;
}
.drop_menu .drop_menu_a:before {
  content: '✈';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -35px;
  transition: 0.5s;
 }
 .drop_menu:hover .drop_menu_a {
  padding-left: 5px;
 }
 
 .drop_menu a{
  font-size: 1rem;
 }
 
 .drop_menu:hover .drop_menu_a:before {
  opacity: 1;
  right: -25px;
 }
 @media (max-width: 992px)
 {
  .info-nav .container{
      justify-content: center!important;
      font-size: 0.8rem;
    }
 }
@media only screen and (max-width: 1200px) {
  .header-logo img{
    height: 40px;
  }
  .nav__item a {
    font-size:0.8rem;
  }
}
@media only screen and (max-width: 991px) {

  .nav__menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0a2b1ec2;
    z-index: 9999;
    display: none;
  }

  .nav__item{
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .nav__list {
    position: absolute;
    background: #fff;
    top: 0;
    right: 0;
    width: 250px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 999;
    padding-top: 80px;
  }

  .mobile__menu {
    display: block;
  }
  .nav__right {
    display: none;
  }

  .active__menu {
    display: block;
  }
}
